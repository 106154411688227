import queryString from 'query-string'
export default function track(key, data) {
   let isProd = !/h5-fat/.test(window.location.href) && process.env.NODE_ENV !== 'development'
   const qs = queryString.stringify({key, ...data})
   data = data || {}
   let src = `https://h5.shinningmorning.com/track?${qs}&path=${window.location.pathname}`
   if (isProd) {
      new Image().src = src
   } else {
      console.log(src)
   }
}