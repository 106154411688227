import queryString from 'query-string'
import { mobileUtil, openApp } from './tools'

const appConfig = {
    'default': {
        cname: '知位'
    },
    'huwei' : {
        cname: '护位'
    },
    'sowei' : {
        cname: '守位'
    },
    'xmf' : {
        cname: '小蜜蜂'
    },
    'peyhc' : {
        cname: '萤火虫'
    }
}

export const productType = {
    weizhi: {
        logo: require('./image/weizhi.png'),
        name: '知位',
        download: 'https://file.shinningmorning.com/app/share/zhiwei.apk',
        appStore: 'https://apps.apple.com/cn/app/id1506334381',
        appLink: {android: 'zhiwei://com.blue.ttdw/open', ios: 'zhiweiapp://'}
    },
    huwei: {
        logo: require('./image/huwei.png'),
        name: '护位',
        download: 'https://file.shinningmorning.com/app/share/huwei.apk',
        appLink: {android: 'huwei://com.blue.huwei/open', ios: 'huweiapp://'}
    },
    sowei: {
        logo: require('./image/sowei.png'),
        name: '守位',
        download: 'https://file.shinningmorning.com/app/share/sowei.apk',
        appLink: {android: 'sowei://com.blue.sowei/open', ios: 'soweiapp://'}
    },
    xmf: {
        logo: require('./image/xmf.png'),
        name: '小蜜蜂',
        download: 'https://file.luobs.cn/app/hmz/xmf.apk',
        appLink: {android: 'xmf://com.blue.xmf/open', ios: 'app.luobs.cn://'},
        appStore: 'https://apps.apple.com/cn/app/id1538096549',
    },
    peyhc: {
        logo: require('./image/peyhc.png'),
        name: '萤火虫',
        download: 'https://file.shinningmorning.com/app/share/yhc.apk',
        // appLink: {android: 'xmf://com.blue.xmf/open', ios: 'app.luobs.cn://'},
        appStore: 'https://apps.apple.com/cn/app/id1582957928',
    }
}

export const tryOpenApp = () => {
    const mu = mobileUtil()
    const appName = getAppName()
    const product = productType[appName]
    if (mu.isAndroid && product && product.appLink) {
        openApp(product.appLink.android)
    }
}

export function getAppCName(appName) {
    const config =  appConfig[appName] || appConfig['default']
    return config.cname
}

export function getAppName() {
    const {appName, app_name} = queryString.parse(window.location.search)
    let appNameParam = appName || app_name || 'weizhi'
    if (window.localStorage) {
        appNameParam = window.localStorage.getItem('appName') || window.localStorage.getItem('app_name') || appNameParam
    }
    
    return appNameParam.replace(/\?.*/g, '')
}

export function getUrl() {
    let url = /h5-fat/.test(window.location.href) ? 'https://app-fat.shinningmorning.com' : 'https://app.shinningmorning.com';
    if (process.env.NODE_ENV === 'development') {
        url = "https://app-fat.shinningmorning.com"
    }
    return url
}