export function openApp(openUrl, downLoadUrl) {
    window.location.href = openUrl;
    downLoadUrl = openUrl
    setTimeout(function () {
            downLoadUrl && download(downLoadUrl);
    }, 3000);
}

export function mobileUtil() {
    var UA = navigator.userAgent,
        isAndroid = /android|adr/gi.test(UA),
        isIOS = /iphone|ipod|ipad/gi.test(UA) && !isAndroid,
        isBlackBerry = /BlackBerry/i.test(UA),
        isWindowPhone = /IEMobile/i.test(UA),
        isMobile = isAndroid || isIOS || isBlackBerry || isWindowPhone;
    return {
        UA,
        isAndroid: isAndroid,
        isIOS: isIOS,
        isMobile: isMobile,
        isWeixin: /MicroMessenger/gi.test(UA),
        isQQ: /QQ/gi.test(UA)
    };
}

export function  download(url) {
    var file_path = url;
    var a = document.createElement('A');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function camel2underscore(str) {
    return str
        .replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
            return "_" + y.toLowerCase();
        })
        .replace(/^_/, "");
}

export function underscore2camel(str) {
    return str.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
    });
}

export function generateArrayHash(array, primaryPropName) {
    array = array || [];
    primaryPropName = primaryPropName || "id";
    var obj = {};
    for (var i = 0, j = array.length; i < j; i++) {
        if (array[i].hasOwnProperty([primaryPropName])) {
            obj[array[i][primaryPropName]] = array[i];
        }
    }
    return obj;
}

export function isEmpty(obj = {}) {
    for (var name in obj) {
        return false;
    }
    return true;
};

export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function stringifyQuery(q) {
    // eslint-disable-next-line array-callback-return
    return Object.keys(q).map(function(key, index) {
        const value = q[key];
        if (value !== null && value !== undefined && value !== '') {
            return `${key}=${encodeURIComponent(value)}`
        }
    }).filter((item) => item).join('&');
}

export function parseQuery(q) {
    //"?offset=0&limit=10&aql=loan.id%20%3E%200"
    if (!q) return {};
    let obj = {};
    q.slice(1).split('&').forEach((current) => {
        const arr = current.split('=')
        if (arr.length !== 2) {
            return;
        }
        obj[arr[0]] = decodeURIComponent(arr[1]) || '';
    })
    return obj;
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export function canJsonStringify(obj) {
    try {
        JSON.stringify(obj);
    } catch (e) {
        return false;
    }
    return true;
}

export function throttle(fn, interval) {
    let canRun = true;
    return function () {
        if (!canRun) { return; }
        canRun = false;
        setTimeout(function () {
            canRun = true;
            fn.apply(this, arguments);
        }, interval);
    }
}

export function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function loadScripts(url) {
    return new Promise(function (resolve, reject) {
        let head = document.head;
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.async = true;
        script.onload = function () {
            resolve(url);
        }
        script.onerror = function () {
            reject(url);
        }
        head.appendChild(script);
    });
}

export function mutate(injections, that) {
    if (!injections) {
        return;
    }

    const fn = function ({ pre, post, key }) {
        const next = that[key];
        that[key] = async () => {
            pre && await pre();
            next && await next();
            post && await post();
        }
    }

    injections.forEach(fn);
}

export function countDown({ start, each, end, cd = 60 }) {
    start && start();

    let timer = setInterval(() => {
        cd--;
        each && each(cd);
        if (cd <= 0) {
            clearInterval(timer)
            end && end();
        }
    }, 1000);
}

const CONFIG = {
    id: 'id',
    parentId: 'parentId',
    children: 'children'
};
export function flatToTree(nodes = [], config = CONFIG) {
    const id = config && config.id;
    const parentId = config && config.parentId;
    const children = config && config.children;
    let idMap = {};
    let jsonTree = [];
    nodes.forEach(item => item[id] && (idMap[item[id]] = item));
    nodes.forEach(item => {
        let parent = idMap[item[parentId]];
        if (parent) {
            !parent[children] && (parent[children] = []);
            parent[children].push(item);
        } else {
            jsonTree.push(item)
        }
    })
    return jsonTree;
}

export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export function deepCopy(target) {
    let copyed_objs = [];
    function _deepCopy(target) {
        if ((typeof target !== 'object') || !target) { return target; }
        for (let i = 0; i < copyed_objs.length; i++) {
            if (copyed_objs[i].target === target) {
                return copyed_objs[i].copyTarget;
            }
        }
        let obj = {};
        if (Array.isArray(target)) {
            obj = [];
        }
        copyed_objs.push({ target: target, copyTarget: obj })
        Object.keys(target).forEach(key => {
            if (obj[key]) { return; }
            obj[key] = _deepCopy(target[key]);
        });
        return obj;
    }
    return _deepCopy(target);
}

export function getLocalStorage(...args) {
    if (!(args instanceof Array)) {
        console.error('getLocalStorage only accpet array arguments');
        return;
    }
    let storage = {};
    args.forEach(arg => {
        let value = window.localStorage.getItem(arg);
        if (isJsonString(value)) {
            value = JSON.parse(value);
        }
        storage[arg] = value;
    });
    return storage;
}

export function setLocalStorage(args) {
    if (!(args instanceof Object)) {
        console.error('setLocalStorage only accpet object arguments');
        return;
    }
    Object.keys(args).forEach(arg => {
        let value = args[arg];
        if (canJsonStringify(value)) {
            value = JSON.stringify(
                value,
                function (k, v) { return v === undefined ? null : v; }
            );
        }
        window.localStorage.setItem(arg, value);
    })
}

export function removeLocalStorage(...args) {
    if (!(args instanceof Object)) {
        console.error('removeLocalStorage only accpet array arguments');
        return;
    }
    args.forEach(arg => {
        window.localStorage.removeItem(arg);
    })
}

export function changeTitle(title) {
    document.title = title;
    let i = document.createElement('iframe');
    i.style.display = 'none';
    i.src = '/favicon.ico'; // 加载当前页面下一个体积小的资源，比如favicon.ico
    i.onload = ()=>{
        setTimeout(()=>{
            i.remove()
        }, 0);
    }
    document.body.appendChild(i);
}
