import axios from 'axios';
import { getAppName } from './app';
import { Toast } from 'antd-mobile';

axios.interceptors.request.use((config) => {
    const appName = getAppName()
    if (config.method === 'post') {
        config.data = {appName, ...config.data}
    } else if (config.method === 'get') {
        config.url = config.url + `${config.url.indexOf('?') === -1 ? '?' : '&'}appName=${appName}`
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const { status, statusText, data = {}, headers = {} } = error.response || {}
    const code = data.code || data.status || status || '';
    const text = data.message || data.msg || statusText || '';
    const reqid = headers.reqid || '';
    Toast.fail(`${text} ${code}: ${reqid})`);
    return Promise.reject(error);
});